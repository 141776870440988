<template>
  <div class="home">
    <!-- Page Container -->
    <div
      id="page-container"
      class="flex flex-col mx-auto w-full min-h-screen bg-gray-100"
    >
      <!-- Page Content -->
      <main id="page-content" class="flex flex-auto flex-col max-w-full">
        <!-- Hero -->
        <!-- Hero Section: Image Side With Simple Header Dark -->
        <div class="bg-gray-800 overflow-hidden">
          <!-- Header -->
          <Header />
          <!-- END Header -->

          <!-- Hero Content -->
          <Hero />
          <!-- END Hero Content -->
        </div>
        <!-- END Hero Section: Image Side With Simple Header Dark -->
        <!-- END Hero -->

        <!-- Section 1 -->
        <SectionOne />
        <!-- END Section 1 -->

        <!-- Section 2 -->
        <SectionSecond />
        <!-- END Section 2 -->

        <!-- Footer -->
        <!-- Footer Section: Simple Vertical With Social Dark -->
        <Footer />
        <!-- END Footer Section: Simple Vertical With Social Dark -->
        <!-- END Footer -->
      </main>
      <!-- END Page Content -->
    </div>
    <!-- END Page Container -->
  </div>
</template>

<script>
import Header from "../components/Header.vue"; // import lottie-web-vue
import Hero from "../components/Hero.vue";
import SectionOne from "../components/SectionOne.vue";
import SectionSecond from "../components/SectionSecond.vue";
import Footer from "../components/Footer.vue";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Header,
    Hero,
    SectionOne,
    SectionSecond,
    Footer,
  },
};
</script>
