<template>
  <div class="home">
    <!-- Page Container -->
    <div
      id="page-container"
      class="flex flex-col mx-auto w-full min-h-screen bg-gray-100"
    >
      <!-- Page Content -->
      <main id="page-content" class="flex flex-auto flex-col max-w-full">
        <!-- Hero -->
        <!-- Hero Section: Image Side With Simple Header Dark -->
        <div class="bg-gray-800 overflow-hidden">
          <!-- Header -->
          <Header />
          <!-- END Header -->

          <!-- Hero Content -->
          <About />
          <!-- END Hero Content -->
        </div>
        <!-- END Hero Section: Image Side With Simple Header Dark -->
        <!-- END Hero -->

        <!-- Footer -->
        <!-- Footer Section: Simple Vertical With Social Dark -->
        <Footer />
        <!-- END Footer Section: Simple Vertical With Social Dark -->
        <!-- END Footer -->
      </main>
      <!-- END Page Content -->
    </div>
    <!-- END Page Container -->
  </div>
</template>

<script>
import Header from "../components/Header.vue"; // import lottie-web-vue
import Footer from "../components/Footer.vue";
import About from "../components/About";
// @ is an alias to /src

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    About,
  },
};
</script>
