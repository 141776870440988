<template>
  <header id="page-header" class="flex flex-none items-center py-10">
    <div
      class="flex flex-col text-center sm:flex-row sm:items-center sm:justify-between space-y-6 sm:space-y-0 container xl:max-w-7xl mx-auto px-4 lg:px-8"
    >
      <div>
        <router-link
          to="/"
          class="inline-flex items-center space-x-2 font-bold text-lg tracking-wide text-white hover:opacity-75"
        >
          <svg
            class="hi-solid hi-code inline-block w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
          <span>Yavuz BEKTAS</span>
        </router-link>
      </div>
      <nav class="space-x-4 sm:space-x-6">
        <router-link
          to="/projects"
          class="font-semibold text-gray-300 hover:text-gray-400"
        >
          <span>Projects</span>
        </router-link>
        <router-link
          to="/about"
          class="font-semibold text-gray-300 hover:text-gray-400"
        >
          <span>About</span>
        </router-link>
        <a
          href="javascript:void(0)"
          class="font-semibold text-gray-300 hover:text-gray-400"
        >
          <span>Blog</span>
        </a>
      </nav>
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style></style>
