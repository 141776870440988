<template>
  <div
    class="flex flex-col lg:flex-row space-y-16 lg:space-y-0 text-center lg:text-left container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32"
  >
    <div class="lg:w-1/2 lg:flex lg:items-center">
      <div>
        <h2 class="text-3xl md:text-4xl font-extrabold mb-4 text-white">
          <span class="text-indigo-300"> Full-stack</span>
          Web Developer
        </h2>
        <h3
          class="text-lg md:text-xl md:leading-relaxed font-medium text-gray-400"
        >
          Hey there! My name is Yavuz. I focus mainly on Vue.js and Node.js as
          these are more popular than other languages in the Web technologies
        </h3>
        <div
          class="flex flex-col sm:flex-row sm:items-center justify-center lg:justify-start space-y-2 sm:space-y-0 sm:space-x-2 pt-10 pb-16"
        >
          <button
            @click="push()"
            type="button"
            class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-6 py-4 leading-6 rounded border-indigo-700 bg-indigo-700 text-white hover:text-white hover:bg-indigo-800 hover:border-indigo-800 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 active:bg-indigo-700 active:border-indigo-700"
          >
            <svg
              class="hi-solid hi-lightning-bolt inline-block w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
                clip-rule="evenodd"
              />
            </svg>
            <span>Contact</span>
          </button>
        </div>
      </div>
    </div>
    <div class="lg:w-1/2 lg:ml-16 lg:flex lg:justify-center lg:items-center">
      <div class="lg:w-96 mx-5 relative">
        <div
          class="absolute pattern-dots-xl text-indigo-800 top-0 left-0 w-32 h-48 md:h-96 transform -translate-y-12 -translate-x-16 -rotate-3"
        ></div>
        <div
          class="absolute pattern-dots-xl text-indigo-800 bottom-0 right-0 w-32 h-48 md:h-96 transform translate-y-12 translate-x-16 rotate-3"
        ></div>
        <div
          class="absolute inset-0 rounded-xl bg-gray-600 bg-opacity-20 -m-4 transform rotate-2"
        ></div>
        <div
          class="absolute inset-0 rounded-xl bg-gray-600 bg-opacity-25 -m-4 transform -rotate-2"
        ></div>
        <lottie-animation
          ref="anim"
          :animationData="require('@/assets/animation.json')"
          :loop="true"
          :autoPlay="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
  methods: {
    push(){
      this.$router.push('/about')
    }
  }
};
</script>

<style></style>
