<template>
  <!-- Page Container -->
  <div
    id="page-container"
    class="flex flex-col mx-auto w-full min-h-screen bg-gray-800"
  >
    <!-- Page Content -->
    <main id="page-content" class="flex flex-auto flex-col max-w-full">
      <!-- Page Section -->
      <div class="max-w-10xl mx-auto p-4 lg:p-8 w-full">
        <!--

      ADD YOUR MAIN CONTENT BELOW

      -->

        <!-- Placeholder -->
        <div
          class="flex items-center justify-center rounded-lg bg-gray-800 border-gray-300 text-gray-500"
        >
          <!-- CTA Section: Featured Image -->
          <div
            class="bg-gradient-to-b via-gray-900 to-gray-900 md:bg-gradient-to-r md:via-gray-900 md:to-gray-900 overflow-hidden"
          >
            <div
              class="space-y-16 container xl:max-w-7xl mx-auto px-4 py-16 lg:px-8 lg:py-32"
            >
              <div
                class="flex flex-col md:flex-row md:items-center space-y-16 md:space-y-0 md:space-x-16"
              >
                <div class="relative">
                  <div
                    class="pattern-dots-xl text-white text-opacity-20 absolute top-0 left-0 w-32 h-32 lg:w-48 lg:h-48 transform -translate-x-10 -translate-y-10"
                  ></div>

                  <div class="relative rounded-lg shadow-xl">
                    <lottie-animation
                      ref="anim"
                      :animationData="require('@/assets/animationabout.json')"
                      :loop="true"
                      :autoPlay="true"
                    />
                  </div>
                </div>
                <div class="space-y-10">
                  <!-- Heading -->
                  <div>
                    <h2
                      class="text-3xl md:text-4xl font-extrabold mb-4 text-white"
                    >
                      Hey!,
                    </h2>
                    <h3
                      class="text-lg md:text-xl md:leading-relaxed font-medium text-gray-400"
                    >
                      If you want to contact me, you are in the right place! The
                      best way to contact me is to use the following apps.
                    </h3>
                  </div>
                  <!-- END Heading -->

                  <!-- Actions -->
                  <div
                    class="flex flex-col lg:flex-row lg:items-center space-y-4 lg:space-y-0 lg:space-x-2"
                  >
                    <a
                      href="https://discordapp.com/users/744234661580111982"
                      class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-6 py-4 leading-6 rounded border-blue-700 text-white hover:text-white hover:bg-indigo-800 hover:border-indigo-800 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 active:bg-indigo-700 active:border-indigo-700"
                    >
                      <svg
                        width="256px"
                        height="256px"
                        viewBox="0 -28.5 256 256"
                        version="1.1"
                        class="opacity-50 hi-solid hi-arrow-right inline-block w-5 h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        preserveAspectRatio="xMidYMid"
                      >
                        <g>
                          <path
                            d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
                            fill="#5865F2"
                            fill-rule="nonzero"
                          ></path>
                        </g>
                      </svg>
                      <span>DC</span>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/yavuz-bektas/"
                      class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-6 py-4 leading-6 rounded border-blue-700 text-white hover:text-white hover:bg-indigo-800 hover:border-indigo-800 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 active:bg-indigo-700 active:border-indigo-700"
                    >
                      <svg
                        height="2500"
                        width="2490"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 256 256"
                        class="opacity-50 hi-solid hi-arrow-right inline-block w-5 h-5"
                      >
                        <g fill="none">
                          <path
                            d="M0 18.338C0 8.216 8.474 0 18.92 0h218.16C247.53 0 256 8.216 256 18.338v219.327C256 247.79 247.53 256 237.08 256H18.92C8.475 256 0 247.791 0 237.668V18.335z"
                            fill="#069"
                          />
                          <path
                            d="M77.796 214.238V98.986H39.488v115.252H77.8zM58.65 83.253c13.356 0 21.671-8.85 21.671-19.91-.25-11.312-8.315-19.915-21.417-19.915-13.111 0-21.674 8.603-21.674 19.914 0 11.06 8.312 19.91 21.169 19.91h.248zM99 214.238h38.305v-64.355c0-3.44.25-6.889 1.262-9.346 2.768-6.885 9.071-14.012 19.656-14.012 13.858 0 19.405 10.568 19.405 26.063v61.65h38.304v-66.082c0-35.399-18.896-51.872-44.099-51.872-20.663 0-29.738 11.549-34.78 19.415h.255V98.99H99.002c.5 10.812-.003 115.252-.003 115.252z"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                      <span>Linkedin</span>
                    </a>
                    <a
                      href="https://github.com/yavuz-bektas/"
                      class="inline-flex justify-center items-center space-x-2 border font-semibold focus:outline-none px-6 py-4 leading-6 rounded border-blue-700 text-white hover:text-white hover:bg-indigo-800 hover:border-indigo-800 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 active:bg-indigo-700 active:border-indigo-700"
                    >
                      <svg
                        width="2500"
                        height="2432"
                        viewBox="0 0 256 249"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMinYMin meet"
                        class="opacity-50 hi-solid hi-arrow-right inline-block w-5 h-5 bg-white"
                      >
                        <g fill="#161614">
                          <path
                            d="M127.505 0C57.095 0 0 57.085 0 127.505c0 56.336 36.534 104.13 87.196 120.99 6.372 1.18 8.712-2.766 8.712-6.134 0-3.04-.119-13.085-.173-23.739-35.473 7.713-42.958-15.044-42.958-15.044-5.8-14.738-14.157-18.656-14.157-18.656-11.568-7.914.872-7.752.872-7.752 12.804.9 19.546 13.14 19.546 13.14 11.372 19.493 29.828 13.857 37.104 10.6 1.144-8.242 4.449-13.866 8.095-17.05-28.32-3.225-58.092-14.158-58.092-63.014 0-13.92 4.981-25.295 13.138-34.224-1.324-3.212-5.688-16.18 1.235-33.743 0 0 10.707-3.427 35.073 13.07 10.17-2.826 21.078-4.242 31.914-4.29 10.836.048 21.752 1.464 31.942 4.29 24.337-16.497 35.029-13.07 35.029-13.07 6.94 17.563 2.574 30.531 1.25 33.743 8.175 8.929 13.122 20.303 13.122 34.224 0 48.972-29.828 59.756-58.22 62.912 4.573 3.957 8.648 11.717 8.648 23.612 0 17.06-.148 30.791-.148 34.991 0 3.393 2.295 7.369 8.759 6.117 50.634-16.879 87.122-64.656 87.122-120.973C255.009 57.085 197.922 0 127.505 0"
                          />
                          <path
                            d="M47.755 181.634c-.28.633-1.278.823-2.185.389-.925-.416-1.445-1.28-1.145-1.916.275-.652 1.273-.834 2.196-.396.927.415 1.455 1.287 1.134 1.923M54.027 187.23c-.608.564-1.797.302-2.604-.589-.834-.889-.99-2.077-.373-2.65.627-.563 1.78-.3 2.616.59.834.899.996 2.08.36 2.65M58.33 194.39c-.782.543-2.06.034-2.849-1.1-.781-1.133-.781-2.493.017-3.038.792-.545 2.05-.055 2.85 1.07.78 1.153.78 2.513-.019 3.069M65.606 202.683c-.699.77-2.187.564-3.277-.488-1.114-1.028-1.425-2.487-.724-3.258.707-.772 2.204-.555 3.302.488 1.107 1.026 1.445 2.496.7 3.258M75.01 205.483c-.307.998-1.741 1.452-3.185 1.028-1.442-.437-2.386-1.607-2.095-2.616.3-1.005 1.74-1.478 3.195-1.024 1.44.435 2.386 1.596 2.086 2.612M85.714 206.67c.036 1.052-1.189 1.924-2.705 1.943-1.525.033-2.758-.818-2.774-1.852 0-1.062 1.197-1.926 2.721-1.951 1.516-.03 2.758.815 2.758 1.86M96.228 206.267c.182 1.026-.872 2.08-2.377 2.36-1.48.27-2.85-.363-3.039-1.38-.184-1.052.89-2.105 2.367-2.378 1.508-.262 2.857.355 3.049 1.398"
                          />
                        </g>
                      </svg>
                      <span>Github</span>
                    </a>
                  </div>
                  <!-- END Actions -->
                </div>
              </div>
            </div>
          </div>
          <!-- END CTA Section: Featured Image -->
        </div>

        <!--

      ADD YOUR MAIN CONTENT ABOVE
           
      --></div>
      <!-- END Page Section -->
    </main>
    <!-- END Page Content -->
  </div>
  <!-- END Page Container -->
</template>

<script>
import LottieAnimation from "lottie-web-vue";

export default {
  components: {
    LottieAnimation,
  },
};
</script>

<style></style>
